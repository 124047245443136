<template>
  <div>
    <TopNavBar
      v-bind:secondTierLoadingDone="secondTierLoadingDone"/>
    <b-container  class="col-12 p-0" >
      <b-row  class="w-100 m-0" >
        <ul class="mt-5 w-100 list-group list-group-horizontal-md">
          <li id="actions"
            class="list-group-item p-2">
          <b-list-group
            class="mt-5 p-0">
            <b-list-group-item class="list-group-item list-group-item-action d-flex justify-content-between active"
               style="max-width: 420px;"
               href="#">
              <div>
                <i class="fas fa-user-circle mr-2"></i>
                <span>{{$route.params.username}}</span>
              </div>
            </b-list-group-item>
            <b-list-group-item class="list-group-item d-flex justify-content-between"
               style="max-width: 420px;">
              <div>
                <i class="far fas fa-poll mr-2"></i>
                <span
                  :title="$t('Bewertung von anderen Accounts')"
                  v-b-tooltip.hover.right>Karma</span>
                <span>: {{total_likes-total_dislikes}}</span>
              </div>
            </b-list-group-item>
            <b-list-group-item class="p-0 list-group-item"
               style="max-width: 420px;"
            v-if="$route.params.username === getUsername()">
              <div class="ml-4 mt-3 mb-3 mr-2"><i class="fas fa-indent small border-0 "></i><span class="ml-2">{{ $t('Placeholder') }}</span></div>
              <table class="table small">
              <tbody>
                <tr>
                  <td class="pl-4">
                    <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="star" value="star" v-model="shortformPlaceholder">
                    <label class="form-check-label" for="star">
                      Gendersternchen <div class="small text-muted">(Student*in)</div>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td class="pl-4">
                    <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="colon" value="colon" v-model="shortformPlaceholder">
                    <label class="form-check-label" for="colon">
                      Gender-Doppelpunkt <div class="small text-muted">(Student:in)</div>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td class="pl-4">
                    <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="lowdash" value="lowdash" v-model="shortformPlaceholder">
                    <label class="form-check-label" for="lowdash">
                      Gender-Gap <div class="small text-muted">(Student_in)</div>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td class="pl-4">
                    <input class="form-check-input mt-2" type="radio" name="shortformPlaceholder" id="slash" value="slash" v-model="shortformPlaceholder">
                    <label class="form-check-label" for="slash">
                      Kurzform <div class="small text-muted">(Student/-in)</div>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
              </b-list-group-item>
          </b-list-group>
          </li>
          <li id="userSpecificdictionaryEntries"
            class="list-group-item w-100 p-0  col-12 col-md-10"
            style="min-width: 300px;">
          <List :filter-by="$route.params.username"
                :secondTierLoadingDone="secondTierLoadingDone=true"
                :shortformPlaceholderProfile="shortformPlaceholder"/>
        </li>
        </ul>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import List from '../../components/List'
import { ProfileDictionaryEntryService } from '../../services/profileDictionaryEntryService'
var Vue = require('vue')

export default {
  name: 'Profile',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ]
    }
  },
  data () {
    return {
      secondTierLoadingDone: false,
      total_likes: 0,
      total_dislikes: 0,
      error: '',
      shortformPlaceholder: ''
    }
  },
  mounted () {
    this.getUserTermStatistics()
  },
  watch: {
    shortformPlaceholder: function () {
      this.updatePlaceholder()
    }
  },
  computed: {
    title: function () {
      return `${this.$route.params.username} · gender app`
    },
    description: function () {
      return `Dieses Profil hat bis anhin ${this.total_likes - this.total_dislikes} gacks gesammelt. 🙌`
    }
  },
  methods: {
    getUsername () {
      return Vue.default.cookie.get('username', 'Profil')
    },
    getUserTermStatistics () {
      ProfileDictionaryEntryService.getProfileDictionaryEntryStatistics(this.$route.params.username)
        .then((response) => {
          // console.log(response)
          this.total_likes = response.data.total_likes
          this.total_dislikes = response.data.total_dislikes
        }).catch((error) => {
          console.log(error)
        })
      ProfileDictionaryEntryService.getProfileDictionaryEntryStatistics(this.getUsername())
        .then((response) => {
          // console.log(response)
          this.shortformPlaceholder = response.data.shortform_placeholder
        }).catch((error) => {
          console.log(error)
        })
    },
    updatePlaceholder () {
      if (this.$route.params.username !== this.getUsername()) return
      ProfileDictionaryEntryService.updatePlaceholder(this.getUsername(), this.shortformPlaceholder)
        .catch((error) => {
          console.log(error)
        })
    }
  },
  components: { List, TopNavBar }
}
</script>

<style scoped>

</style>
